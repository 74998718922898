<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="12"
        v-show="
          !$route.params.cmd &&
          !$route.params.pfm &&
          !$route.params.dmd &&
          !$route.params.facture &&
          !$route.params.dmd
        "
      >
        <listitemspage
          :selitem="selitem"
          :qSelect="Qselect"
          :qSelectall="Qselectall"
          :qDelete="Qdelete"
          :PageResponse="'tiers'"
          :AllResponse="'alltiers'"
          :title="'Liste des Tiers'"
          :headers="headers"
          :field="'DATECREAT'"
          :filename="tier_label"
          :sheetname="tier_label"
          :showedit="false"
          :TypeScope="tiertype"
          :CatScope="categories"
          :byexercice="true"
          :del_disable="
            tier.cmdCount > 0 ||
            tier.prdcCount > 0 ||
            tier.prdfCount > 0 ||
            (tier.produits_vente ? tier.produits_vente.length > 0 : false) ||
            (tiertype == 0 &&
              (tier.code_client != null || tier.code_fournisseur != null)) ||
            tier.statut_id > 1
          "
          @open="OpenTierForm"
          @rowselect="TierChange"
          @exercice_change="exercice_change"
          :btn1="
            tiertype == 2
              ? { text: 'etat 104', icon: 'mdi-file-document' }
              : { text: '', icon: '' }
          "
          :btn2="
            $store.state.isadmin && $store.state.me.id == 1
              ? { text: 'Maj SI', icon: 'mdi-account-cash-outline' }
              : {}
          "
          :btn3="{ text: 'Filtre Avancé', icon: 'mdi-tune' }"
          :btn4="unfilter ? { text: '', icon: 'mdi-filter-remove' } : {}"
          @click1="e104"
          @click2="OpenSIForm"
          @click3="OpenFilterForm"
          @click4="unfiltrer"
          :Add="editer"
          :del="editer"
          :list_options="false"
          :key="klist"
          :search_elm="filtre"
          v-show="$route.params.tier_id != -1"
          :load="loading"
        >
        </listitemspage>
      </v-col>
    </v-row>
    <tierform
      :item="tier"
      :key="ktier"
      :client="true"
      :showForm="!isTierClosed"
      @close="closeTierForm"
      :status_list="status_list"
      :agences="agences"
      :countrys="countrys"
      :cat_tier="cat_tier"
      :cat_product="cat_product"
      :plancomptable="plancomptable"
      @refresh_pl="refresh_pl"
      :modify="
        editer &&
        (tiertype != 0 ||
          (tiertype == 0 && !tier.code_client && !tier.code_fournisseur))
      "
      :valider="valider"
      :istier="tiertype"
      :modes="modes"
      :modalites="modalites"
      :users="users"
      :currencies="currencies"
      :route="route"
    >
    </tierform>
    <formlist
      :title="title"
      :list="form_list"
      :showform="!isListClose"
      :listheaders="listheaders"
      :key="frml"
      :imp_excel="imp_si"
      :btn_save="imp_si"
      :btn_save_dis="btn_save_dis"
      :width="width"
      @excel_import="excel_si"
      @close="isListClose = true"
      @save="save"
      :Total="true"
      :master="true"
      :alert="alert"
      @input="alert = false"
      :type_alert="type_alert"
      :message="message"
      :orientation="orientation"
    >
    </formlist>
    <filterform
      :showForm="!isFilterClosed"
      :Product="false"
      :categories_list="cat_tier"
      @close="closeFilterForm"
    >
    </filterform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-container>
</template>

<script>
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
import TIERS from "../graphql/Tier/TIERS.gql";
import TIER from "../graphql/Tier/TIER.gql";
import ALLTIERS from "../graphql/Tier/ALLTIERS.gql";
import E104 from "../graphql/Tier/E104.gql";
import DELETE_TIERS from "../graphql/Tier/DELETE_TIER.gql";
import DATA from "../graphql/Tier/DATA.gql";
import PLAN_CMPT from "../graphql/Tier/PLAN_CMPT.gql";
import UPDATE_SI from "../graphql/Tier/UPDATE_SI.gql";
import { myBL } from "print/data.js";

export default {
  components: {
    listitemspage: () => import("../components/ListItemsPages.vue"),
    tierform: () => import("../views/TierForm.vue"),
    filterform: () => import("../components/FilterForm.vue"),
    formlist: () => import("../components/FormList.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  props: {
    tiertype: Number,
  },
  data: () => ({
    selitem: {},
    unfilter: false,
    width: "1000px",
    orientation: "p",
    headers: [
      {
        text: "Code Client",
        value: "code_client",
        enum: "CODE_CLIENT",
        hiden: true,
        selected: true,
      },

      {
        text: "Code Fournisseur",
        value: "code_fournisseur",
        enum: "CODE_FOURNISSEUR",
        hiden: true,
        selected: true,
      },
      {
        text: "Forme Juridique",
        value: "forme_juridique",
        enum: "FORME_JURIDIQUE",
        selected: true,
      },

      {
        text: "Nom",
        align: "start",
        value: "nom",
        enum: "NOM",
        selected: true,
        slot: "href",
      },
      {
        text: "Statut",
        value: "statuts_name",
        enum: "STATUTS_NAME",
        selected: true,
        list: ["Att. Validation", "Actif", "A surveiller", "Bloqué"],
        slot: "chip",
        color: "color",
      },
      {
        text: "Type",
        value: "tier_type_name",
        enum: "TIER_TYPE_NAME",
        list: [],
        selected: true,
      },
      {
        text: "Departement",
        value: "departement_name",
        enum: "DEPARTEMENT_NAME",
        list: [],
        selected: true,
      },
      {
        text: "Ville",
        value: "ville_name",
        enum: "VILLE_NAME",
        list: [],
        selected: true,
      },
      {
        text: "Géré Par",
        value: "user_name",
        enum: "USER_NAME",
        list: [],
        selected: true,
      },
      {
        text: "RC",
        value: "RC",
        enum: "RC",
        selected: false,
      },
      {
        text: "ART",
        value: "ART",
        enum: "ART",
        selected: false,
      },
      {
        text: "NIS",
        value: "NIS",
        enum: "NIS",
        selected: false,
      },
      {
        text: "NIF",
        value: "NIF",
        enum: "NIF",
        selected: false,
      },
      {
        text: "Date de Création",
        value: "date_crea",
        slot: "date",
        enum: "DATECREAT",
        selected: true,
      },
      {
        text: "Auxiliaire",
        value: "aux",
        enum: "AUX",
        selected: false,
      },
      {
        text: "Code Comptable",
        value: "account_number",
        enum: "ACCOUNT_NUMBER",
        selected: false,
      },
    ],
    form_list: [],
    categories: [],
    cat_tier: [],
    cat_product: [],
    tiers_list: [],
    Qselect: {},
    Qselectall: {},
    Qdelete: {},
    Qdetaildelete: {},
    isTierClosed: true,
    isFilterClosed: true,
    tier: {},
    loading: false,
    isListClose: true,
    imp_si: false,
    title: "",
    frml: 600,
    status_list: [],
    agences: [],
    countrys: [],
    departements: [],
    villes: [],
    plancomptable: [],
    btn_save_dis: true,
    editer: false,
    valider: false,
    klist: 0,
    ktier: 100,
    modes: [],
    modalites: [],
    users: [],
    currencies: [],
    alert: false,
    type_alert: "info",
    message: "",
    listheaders: [],
    sicheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Code",
        value: "code_tier",
        selected: true,
      },
      {
        text: "Raison",
        value: "nom",
        selected: true,
      },
      {
        text: "Situation Expedition",
        value: "sit_bl",
        align: "end",
        slot: "cur",
        total: "sit_bl",
        selected: true,
      },
      {
        text: "Montant HT",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        total: "mont_ht",
        selected: true,
      },
      {
        text: "TVA",
        value: "mont_tva",
        align: "end",
        slot: "cur",
        total: "mont_tva",
        selected: true,
      },
      {
        text: "Montant TTC",
        value: "mont_ttc",
        align: "end",
        slot: "cur",
        total: "mont_ttc",
        selected: true,
      },
      {
        text: "Date",
        value: "date",
        slot: "date",
        selected: true,
      },
    ],
    e104headers: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },

      {
        text: "Article",
        value: "ART",
        selected: true,
        style: { halign: "left", cellWidth: "wrap", fontSize: 10 },
      },
      {
        text: "Registre",
        value: "RC",
        selected: true,

        style: { halign: "leftt", cellWidth: "wrap", fontSize: 10 },
      },
      {
        text: "Matricule",
        value: "NIF",
        selected: true,

        style: { halign: "left", cellWidth: "wrap", fontSize: 10 },
      },
      {
        text: "Raison",
        value: "nom",
        selected: true,

        style: { halign: "left", cellWidth: "auto", fontSize: 8 },
      },
      {
        text: "Adresse",
        value: "adresse",
        selected: true,

        style: { halign: "left", cellWidth: "auto", fontSize: 8 },
      },
      {
        text: "TOTAL HT",
        value: "mont_ht",
        align: "end",
        slot: "cur",
        width: "auto",
        total: "mont_ht",
        selected: true,

        style: { halign: "right", cellWidth: "wrap", fontSize: 10 },
      },
      {
        text: "TOTAL TVA",
        value: "mont_tva",
        align: "end",
        slot: "cur",
        total: "mont_tva",
        width: "auto",
        selected: true,

        style: { halign: "right", cellWidth: "wrap", fontSize: 10 },
      },
      {
        text: "TOTAL TIMBRE",
        value: "mont_timbre",
        align: "end",
        slot: "cur",
        total: "mont_timbre",
        width: "auto",
        selected: true,

        style: { halign: "right", cellWidth: "wrap", fontSize: 10 },
      },
    ],
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    filtre: "",
    year: null,
    route: {},
  }),

  computed: {
    tiertypes() {
      return this.tiertype == 0
        ? this.$store.state.tiers_types.filter((elm) => elm.id == 13)
        : this.tiertype == 2
        ? this.$store.state.tiers_types.filter((elm) => elm.type == "C")
        : this.$store.state.tiers_types.filter((elm) => elm.type == "F");
    },
    keytier() {
      return this.tier.id;
    },
    tier_label() {
      return this.tiertype == 0
        ? "Tier"
        : this.tiertype == 2
        ? "Client"
        : "Fournisseur";
    },
  },
  watch: {
    tiertype: {
      handler() {
        this.get_data();
      },
    },
    "$store.state.filtre": {
      handler() {
        if (this.$store.state.filtre) {
          this.filtre = this.$store.state.filtre.filter_by;
          this.selitem = this.$store.state.filtre.obj;
          this.klist++;
        }
      },
    },
  },
  created() {
    if (this.$route.params.filtre) {
      this.filtre = this.$route.params.filtre;
      this.selitem = this.$route.params.obj;
      this.klist++;
    }
    this.sicheaders[2].text = this.tier_label;

    this.headers[14].hiden = !(
      myBL == "n" &&
      (this.$store.state.auth.includes("05021") || this.$store.state.isadmin)
    );
  },

  async mounted() {
    this.Qselect = TIERS;
    this.Qselectall = ALLTIERS;
    this.Qdelete = DELETE_TIERS;
    this.year = this.$store.state.exercice;
    this.editer =
      (this.tiertype == 0 && this.$store.state.auth.includes("02001")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("02030")) ||
      (this.tiertype == 1 && this.$store.state.auth.includes("02029")) ||
      this.$store.state.isadmin;

    this.valider =
      (this.tiertype == 0 && this.$store.state.auth.includes("03001")) ||
      (this.tiertype == 2 && this.$store.state.auth.includes("03030")) ||
      (this.tiertype == 1 && this.$store.state.auth.includes("03029")) ||
      this.$store.state.isadmin;
    await this.get_data();
    this.headers[5].list = this.tiertypes.map((elm) => elm.tier_type);
    this.headers[6].list = this.departements.map((elm) => elm.nom_departement);
    this.headers[7].list = this.villes.map((elm) => elm.nom_ville);
    this.headers[8].list = this.users.map((elm) => elm.name);
    if (
      this.$route.params.pfm ||
      this.$route.params.cmd ||
      this.$route.params.mvm ||
      this.$route.params.facture ||
      this.$route.params.dmd
    ) {
      if (this.$route.params.tier_id > 0) {
        this.tier.id = this.$route.params.tier_id;
        this.OpenTierForm(this.tier);
      } else {
        this.tier.id = -1;
        this.route.pfm = this.$route.params.pfm;
        this.route.route_name = this.$route.params.name;
        this.tier.nom = this.$route.params.tier_name
          ? this.$route.params.tier_name
          : "";
        this.OpenTierForm(this.tier);
      }
    }
    // if (this.$route.params.code_tier || this.$route.params.tier_name) {
    //   if (this.$route.params.tier_id == -1) {
    //     this.tier.id = -1;
    //     this.tier.nom = this.$route.params.tier_name
    //       ? this.$route.params.tier_name
    //       : "";
    //     this.OpenTierForm(this.tier);
    //   } else {
    //     this.filtre = this.$route.params.code_tier;
    //     this.klist++;
    //     this.selitem.id = this.$route.params.tier_id;
    //   }
    // }
  },

  methods: {
    async refresh_pl() {
      let r = await this.requette(PLAN_CMPT, null, "no-cache");
      if (r) this.plancomptable = r.plancomptable;
    },
    async requette(query, v, f) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: f,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async save() {
      let ok = true;
      this.message = null;
      let list_m = [];
      let list_f = [];
      let list_p = [];
      for (let index = 0; index < this.form_list.length; index++) {
        const element = this.form_list[index];

        if (!element.code_tier && ok) {
          this.message =
            "Code " +
            this.tier_label +
            " obligatoire " +
            " - ligne " +
            (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (!element.nom && ok) {
          this.message =
            this.tier_label + " introuvable " + " - ligne " + (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }

        if (ok) {
          let i = this.form_list.findIndex(
            (elm) => elm.tier_id == element.tier_id
          );
          if (i != index) {
            this.message =
              this.tier_label +
              " en double pour " +
              element.code_tier +
              " - lignes " +
              (index + 1 + " et " + (i + 1));
            this.type_alert = "error";
            this.alert = true;
            ok = false;
            break;
          }
        }
        if (typeof element.sit_bl != "number" && ok) {
          this.message =
            "Montant Situation Expédition invalide pour le " +
            this.tier_label +
            element.code_tier +
            " - ligne " +
            (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (typeof element.mont_ht != "number" && ok) {
          this.message =
            "Montant HT invalide pour le " +
            this.tier_label +
            element.code_tier +
            " - ligne " +
            (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (typeof element.mont_tva != "number" && ok) {
          this.message =
            "Montant TVA invalide pour le " +
            this.tier_label +
            element.code_tier +
            " - ligne " +
            (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (typeof element.mont_ttc != "number" && ok) {
          this.message =
            "Montant TTC invalide pour le " +
            this.tier_label +
            element.code_tier +
            " - ligne " +
            (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (!(element.date instanceof Date) && ok) {
          this.message =
            "Date inccorrecte pour le " +
            this.tier_label +
            element.code_tier +
            " - ligne " +
            (index + 1);
          this.type_alert = "error";
          this.alert = true;
          ok = false;
          break;
        }
        if (element.sit_bl > 0)
          list_m.push({
            tier_id: element.tier_id,
            mvm_type: this.tiertype == 2 ? 3 : 1,
            code_tier: element.code_tier,
            mont_ttc: element.sit_bl,
            mvm_date: element.date.toISOString().slice(0, 10),
            write_uid: this.$store.state.me.id,
          });
        if (element.sit_bl < 0)
          list_p.push({
            tier_id: element.tier_id,
            paye_type: this.tiertype == 2 ? 3 : 1,
            code_tier: element.code_tier,
            montant: -1 * element.sit_bl,
            modepaiement: 4,
            facture: 0,
            paye_date: element.date.toISOString().slice(0, 10),
            write_uid: this.$store.state.me.id,
          });
        if (element.mont_ttc > 0)
          list_f.push({
            tier_id: element.tier_id,
            code_tier: element.code_tier,
            fact_type: this.tiertype == 2 ? 3 : 1,
            mont_ht: element.mont_ht,
            mont_tva: element.mont_tva,
            mont_ttc: element.mont_ttc,
            fact_date: element.date.toISOString().slice(0, 10),
            write_uid: this.$store.state.me.id,
          });
        if (element.mont_ttc < 0)
          list_p.push({
            tier_id: element.tier_id,
            code_tier: element.code_tier,
            paye_type: this.tiertype == 2 ? 3 : 1,
            montant: -1 * element.mont_ttc,
            modepaiement: 1,
            facture: 1,
            paye_date: element.date.toISOString().slice(0, 10),
            write_uid: this.$store.state.me.id,
          });
      }
      if (ok) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir mettre a jour la situation initiale?",
            { color: "orange darken-3" },
            "Tous les lettrages correspondant seront supprimés!!"
          )
        ) {
          let v = {
            list_m: list_m,
            list_f: list_f,
            list_p: list_p,
          };
          let r = await this.maj(UPDATE_SI, v);
          if (r) {
            this.snackbar_text =
              "Situation Initiale des " +
              this.tier_label +
              "s mise a jour avec succes";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.isListClose = true;
            this.klist++;
          }
        }
      }
    },

    async excel_si(items) {
      // let f = items[0];
      this.alert = false;
      let ok = true;
      let v = {
        TypeScope: this.tiertype,
      };
      let r = await this.requette(ALLTIERS, v);
      if (r) this.tiers_list = r.alltiers;
      if (
        arraysEqual(items.headers, [
          "No",
          "Code",
          this.tier_label,
          "Situation Expedition",
          "Montant HT",
          "TVA",
          "Montant TTC",
          "Date",
        ]) == false
      ) {
        this.snackbar_text = "format fichier incompatible!";
        this.snackbar_color = "error";
        this.snackbar = true;
        ok = false;
      }

      if (ok) {
        let list = [];

        for (let index = 0; index < items.results.length; index++) {
          const element = items.results[index];
          let elm = {};
          let i = this.tiers_list.findIndex(
            (elm) =>
              (this.tiertype == 2 ? elm.code_client : elm.code_fournisseur) ==
              element["Code"]
          );
          elm.id = list.length + 1;
          if (i >= 0) {
            elm.nom = this.tiers_list[i].nom;
            elm.tier_id = this.tiers_list[i].id;
            elm.code_tier =
              this.tiertype == 2
                ? this.tiers_list[i].code_client
                : this.tiertype == 1
                ? this.tiers_list[i].code_fournisseur
                : "";
          }
          elm.sit_bl = element["Situation Expedition"];
          elm.mont_ht = element["Montant HT"];
          elm.mont_tva = element["TVA"];
          elm.mont_ttc = element["Montant TTC"];
          elm.date = element.Date;

          list.push(elm);
        }
        if (list.length > 0) {
          this.btn_save_dis = false;
          this.form_list = list;
          this.frml++;
        }
      }
    },
    async maj(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async get_data() {
      if (this.tiertype == 0) {
        this.headers[0].hiden = false;
        this.headers[1].hiden = false;
      }
      if (this.tiertype == 1) {
        this.headers[0].hiden = true;
        this.headers[1].hiden = false;
      }
      if (this.tiertype == 2) {
        this.headers[0].hiden = false;
        this.headers[1].hiden = true;
      }
      this.loading = true;

      let r = await this.requette(DATA);
      if (r) {
        this.status_list = r.statuts;
        this.agences = r.agences;
        this.countrys = r.countrys;
        this.departements = r.departements;
        this.villes = r.villes;
        this.cat_tier = r.categorie.filter((elm) => elm.type_cat == 1);
        this.cat_product = r.categorie.filter(
          (elm) => elm.type_cat == 2 || elm.type_cat == 3
        );
        this.plancomptable = r.plancomptable;
        this.modes = r.modes;
        this.modalites = r.modalites;
        this.users = r.users;
        this.currencies = r.currencies;
      }
      //

      this.loading = false;
    },
    exercice_change(exercice) {
      this.year = exercice;
    },
    async TierChange(item) {
      // this.tier = item;
      this.selitem = item;
      //this.pf++;
    },
    async OpenTierForm(item) {
      if (item.id < 0) {
        this.tier = item;
        this.$store.dispatch("Editing", true);
      } else {
        let r = await this.requette(
          TIER,
          {
            where: { column: "ID", value: item.id },
          },
          "no-cache"
        );
        if (r) {
          this.tier = r.tiers.data[0];
          if (this.$route.params.pfm) {
            this.route.pfm = this.$route.params.pfm;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.pfm.id;
          }
          if (this.$route.params.cmd) {
            this.route.cmd = this.$route.params.cmd;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.cmd.id;
          }
          if (this.$route.params.product) {
            this.route.product = this.$route.params.product;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.product.id;
          }
          if (this.$route.params.dmd) {
            this.route.dmd = this.$route.params.dmd;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.dmd.id;
          }
          if (this.$route.params.mvm) {
            this.route.mvm = this.$route.params.mvm;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.mvm.id;
          }
          if (this.$route.params.facture) {
            this.route.facture = this.$route.params.facture;
            this.route.route_name = this.$route.params.name;
            this.route.item_id = this.$route.params.facture.id;
          }
        }
      }
      this.isTierClosed = false;
      this.ktier++;
      // this.tier = item;
      // this.selitem = item;
      // if (this.$route.params.name) {
      //   this.tier.route_name = this.$route.params.name;
      //   if (this.$route.params.dmd) this.tier.dmd = this.$route.params.dmd;
      //   if (this.$route.params.pfm) this.tier.pfm = this.$route.params.pfm;
      //   if (this.$route.params.cmd) this.tier.cmd = this.$route.params.cmd;
      //   if (this.$route.params.mvm) this.tier.mvm = this.$route.params.mvm;
      //   if (this.$route.params.facture)
      //     this.tier.facture = this.$route.params.facture;
      // }
      // this.ktier++;
      // this.isTierClosed = false;
    },
    closeTierForm() {
      this.isTierClosed = true;
    },
    OpenFilterForm() {
      this.isFilterClosed = false;
    },
    async e104() {
      let v = {
        year: this.year,
      };
      let r = await this.requette(E104, v);
      if (r) {
        this.form_list = r.e104;
        this.width = "80%";
        this.orientation = "l";
        this.title = "Etat 104";
        this.listheaders = this.e104headers;
        this.isListClose = false;
        this.imp_si = false;
        this.frml++;
      }
    },
    closeFilterForm(cat) {
      if (cat) {
        this.unfilter = true;
        this.categories = cat;
        this.$store.dispatch("Changed", true);
      }
      this.isFilterClosed = true;
    },
    unfiltrer() {
      this.unfilter = false;
      this.categories = [];
      this.$store.dispatch("Changed", true);
    },
    OpenSIForm() {
      this.form_list = [];
      this.title = "MAJ Situation Intiale du tier : " + this.tier_label;
      this.listheaders = this.sicheaders;
      this.width = "1000px";
      this.orientation = "p";
      this.isListClose = false;
      this.imp_si = true;
      this.frml++;
    },
  },
};
</script>
